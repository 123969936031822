import { Controller } from 'stimulus';
import { gsap } from 'gsap';

export default class extends Controller {
  static targets = ['message', 'progressBar']

  connect() {
    this.boundTurboStreamHandler = this.handleTurboStream.bind(this);
    this.boundInitializeMessage = this.initializeMessage.bind(this);
    document.addEventListener(
      'turbo:before-stream-render',
      this.boundTurboStreamHandler
    );

    // Initialize any existing messages
    this.initializeMessages();
  }

  disconnect() {
    document.removeEventListener(
      'turbo:before-stream-render',
      this.boundTurboStreamHandler
    );
  }

  handleTurboStream(event) {
    const { action, target } = event.detail.newStream;

    if (target === 'flash_trade_messages' && action === 'append') {
      event.preventDefault();
      this.handleTurboAppend(event.detail.newStream);
    }
  }

  handleTurboAppend(newStream) {
    const templateContent = newStream.querySelector('template').content;

    // First try to find the message element directly
    let messageElement = Array.from(templateContent.childNodes).find(
      node => node.nodeType === Node.ELEMENT_NODE && node.classList.contains('pointer-events-auto')
    );

    // If not found directly, look inside any anchor tags
    let anchorElement = Array.from(templateContent.childNodes).find(
      node => node.nodeType === Node.ELEMENT_NODE && node.tagName === 'A'
    );

    if (!messageElement && !anchorElement) {
      console.error('Could not find message element in template');
      return;
    }

    // Clone the appropriate element
    const elementToClone = anchorElement || messageElement;
    const messageClone = elementToClone.cloneNode(true);

    // Style link if present
    if (messageClone.tagName === 'A') {
      messageClone.classList.add('w-full', 'max-w-sm');
    }

    // Initially set the starting position
    gsap.set(messageClone, {
      opacity: 0,
      y: 20,
      x: window.innerWidth >= 640 ? 20 : 0
    });

    // Append the message to the DOM
    this.element.querySelector('#flash_trade_messages').appendChild(messageClone);

    // Initialize the message
    this.initializeMessage(messageClone);

    // Animate in
    gsap.to(messageClone, {
      opacity: 1,
      y: 0,
      x: 0,
      duration: 0.3,
      ease: 'power2.out'
    });
  }

  initializeMessages() {
    this.messageTargets.forEach(this.boundInitializeMessage);
  }

  initializeMessage(messageElement) {
    // Set up close button
    const closeButton = messageElement.querySelector('button');
    closeButton.setAttribute('data-action', 'click->flash-trade#close');

    // Add hover listeners
    messageElement.setAttribute('data-action', 'mouseenter->flash-trade#pauseTimer mouseleave->flash-trade#resumeTimer');

    // Store references for the timer
    const progressBar = messageElement.querySelector('[data-flash-trade-target="progressBar"]');
    messageElement.dismissTimer = {
      startTime: Date.now(),
      duration: 4000,
      remaining: 4000,
      paused: false,
      progressBar: progressBar
    };

    // Start progress bar animation
    gsap.to(progressBar, {
      width: '0%',
      duration: 4,
      ease: 'none'
    });

    // Start the timer
    this.startTimer(messageElement);
  }

  startTimer(messageElement) {
    const timer = messageElement.dismissTimer;
    if (!timer) return;

    timer.intervalId = setInterval(() => {
      if (!timer.paused) {
        const elapsed = Date.now() - timer.startTime;
        timer.remaining = Math.max(0, timer.duration - elapsed);

        if (timer.remaining <= 0) {
          this.removeMessage(messageElement);
        }
      }
    }, 100);
  }

  pauseTimer(event) {
    const messageElement = event.currentTarget;
    const timer = messageElement.dismissTimer;
    if (!timer) return;

    timer.paused = true;
    const elapsed = Date.now() - timer.startTime;
    timer.remaining = Math.max(0, timer.duration - elapsed);

    // Pause progress bar animation
    gsap.getTweensOf(timer.progressBar)[0]?.pause();
  }

  resumeTimer(event) {
    const messageElement = event.currentTarget;
    const timer = messageElement.dismissTimer;
    if (!timer) return;

    timer.paused = false;
    timer.startTime = Date.now() - (timer.duration - timer.remaining);

    // Resume progress bar animation
    const progressTween = gsap.getTweensOf(timer.progressBar)[0];
    if (progressTween) {
      progressTween.duration(timer.remaining / 1000);
      progressTween.play();
    }
  }

  close(event) {
    event.preventDefault();  // Prevent link click
    event.stopPropagation(); // Stop event bubbling
    const messageElement = event.currentTarget.closest('[data-flash-trade-target="message"]');
    this.removeMessage(messageElement);
  }

  removeMessage(messageElement) {
    // Clear the interval if it exists
    if (messageElement.dismissTimer?.intervalId) {
      clearInterval(messageElement.dismissTimer.intervalId);
    }

    // Get the element to remove (either the message or its parent link)
    const elementToRemove = messageElement.dismissTimer?.parentElement || messageElement;

    gsap.to(elementToRemove, {
      opacity: 0,
      duration: 0.3,
      ease: 'power2.in',
      onComplete: () => {
        elementToRemove.remove();
      }
    });
  }
}
