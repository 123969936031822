import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
import { flash } from '../lib/flashes'

export default class extends Controller {
  static values = {
    updateUrl: String,
    verb: { type: String, default: 'POST' },
  }

  static targets = ['list']

  static classes = ['drag', 'ghost', 'chosen', 'fallback']

  connect() {
    new Sortable(this.listTarget, {
      animation: 250,
      easing: "cubic-bezier(0.4, 0, 0.2, 1)",
      dragClass: this.hasDragClass ? this.dragClass : 'sortable-drag',
      ghostClass: this.hasGhostClass ? this.ghostClass : 'sortable-ghost',
      chosenClass: this.hasChosenClass ? this.chosenClass : 'sortable-chosen',
      forceFallback: true,
      fallbackClass: this.hasFallbackClass ? this.fallbackClass : 'sortable-fallback',
      fallbackOnBody: true,
      onStart: (_evt) => {
        document.body.style.cursor = 'grabbing'
      },
      onEnd: (_evt) => {
        document.body.style.cursor = 'default'
        const positionData = Array.from(this.listTarget.children).map(
          (el, index) => ({
            id: el.dataset.id,
            position: index,
          })
        )
        this.#updatePositions(positionData)
      }
    })
  }

  #updatePositions = (positionData) => {
    fetch(this.updateUrlValue, {
      method: this.verbValue,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.CSRFToken,
      },
      body: JSON.stringify({ position_data: positionData })
    }).then((response) => {
      if (response.ok) {
        flash('Positions updated successfully', 1000)
      } else if (response.status === 422) {
        flash('Sorting failed due to an unexpected error')
      } else {
        flash('Error. Please retry')
      }
    })
  }

  get CSRFToken() {
    const csrfTokenElement = document.querySelector('meta[name="csrf-token"]')
    return csrfTokenElement.content
  }
}
