import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "warning"]

  connect() {
    this.originalValue = this.inputTarget.value
  }

  checkChange() {
    const currentValue = this.inputTarget.value
    if (currentValue !== this.originalValue && this.originalValue !== "") {
      this.warningTarget.classList.remove("hidden")
    } else {
      this.warningTarget.classList.add("hidden")
    }
  }
} 
