import { Controller } from 'stimulus';
import gsap from 'gsap';
import { hideElement, showElement } from '~/helpers/animations_helpers';

export default class extends Controller {
  connect() {
    this.boundHandleTurboStream = this.handleTurboStream.bind(this);
    document.removeEventListener(
      'turbo:before-stream-render',
      this.boundHandleTurboStream
    );
    document.addEventListener(
      'turbo:before-stream-render',
      this.boundHandleTurboStream
    );
  }

  disconnect() {
    document.removeEventListener(
      'turbo:before-stream-render',
      this.boundHandleTurboStream
    );
  }

  async fadeOutFadeIn(oldContent, newContent) {
    await gsap.to(oldContent, {
      opacity: 0,
      duration: 0.2,
      ease: 'power1.out',
    });

    oldContent.replaceWith(newContent);

    gsap.set(newContent, { opacity: 0 });

    await gsap.to(newContent, {
      opacity: 1,
      duration: 0.2,
      ease: 'power1.in',
    });
  }

  async handleTurboStream(event) {
    const streamElement = event.target;

    if (
      streamElement.action === 'replace' &&
      streamElement.target === 'platform_invitations'
    ) {
      event.preventDefault();

      const template = streamElement.querySelector('template');
      if (!template) {
        console.error('No template found in stream');
        return;
      }

      const newContent = template.content.firstElementChild;
      const currentContent = document.getElementById('platform_invitations');

      if (!newContent || !currentContent) {
        console.error('Missing content elements', {
          newContent,
          currentContent,
        });
        Turbo.renderStreamMessage(event.target.outerHTML);
        return;
      }

      await this.fadeOutFadeIn(currentContent, newContent);
    } else if (
      streamElement.action === 'prepend' &&
      streamElement.target === 'platform_invitations'
    ) {
      event.preventDefault();

      const template = streamElement.querySelector('template');
      if (!template) {
        console.error('No template found in stream');
        return;
      }

      const container = document.getElementById(streamElement.target);
      const newContent = template.content.firstElementChild;

      if (!container || !newContent) {
        console.error('Missing elements for prepend', {
          container,
          newContent,
        });
        Turbo.renderStreamMessage(event.target.outerHTML);
        return;
      }

      container.insertBefore(newContent, container.firstChild);
      await showElement(newContent);
    } else if (
      streamElement.action === 'append' &&
      (streamElement.target === 'platform_managers' ||
        streamElement.target === 'platform_invitations')
    ) {
      event.preventDefault();

      const template = streamElement.querySelector('template');
      if (!template) {
        console.error('No template found in stream');
        return;
      }

      const container = document.getElementById(streamElement.target);
      const newContent = template.content.firstElementChild;

      if (!container || !newContent) {
        console.error('Missing elements for append', { container, newContent });
        Turbo.renderStreamMessage(event.target.outerHTML);
        return;
      }

      container.appendChild(newContent);
      await showElement(newContent);
    } else if (
      streamElement.action === 'remove' &&
      /^platform_invitation_\d+_card$/.test(streamElement.target)
    ) {
      event.preventDefault();

      const element = document.getElementById(streamElement.target);
      if (!element) {
        console.error('Element not found for removal');
        return;
      }

      await hideElement(element);
      element.remove();
    } else if (
      streamElement.action === 'remove' &&
      /^platform_(manager|invitation)_\d+_card$/.test(streamElement.target)
    ) {
      event.preventDefault();

      const element = document.getElementById(streamElement.target);
      if (!element) {
        console.error('Element not found for removal');
        return;
      }

      await hideElement(element);
      element.remove();
    }
  }
}
