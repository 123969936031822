import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["label", "input", "submit"]

  connect() {
    // Set initial states
    this.updateStyles()

    // Store initial role selection
    this.initialRole = this.inputTargets.find(input => input.checked)?.value

    // Disable submit button initially
    this.submitTarget.disabled = true
  }

  inputTargetConnected(input) {
    this.updateLabelStyle(input)
  }

  change(event) {
    if (event.target.type === 'radio') {
      this.labelTargets.forEach(label => {
        this.resetLabelStyle(label)
      })
    }

    this.updateLabelStyle(event.target)
    this.updateSubmitButton(event.target.value)
  }

  updateLabelStyle(input) {
    const label = input.closest('[data-member-input-target="label"]')
    if (!label) return

    if (input.checked) {
      label.classList.remove('bg-card-background-alternative', 'border-gray-600')
      label.classList.add('bg-gray-600', 'border-gray-500')
    } else {
      this.resetLabelStyle(label)
    }
  }

  resetLabelStyle(label) {
    label.classList.remove('bg-gray-600', 'border-gray-500')
    label.classList.add('bg-card-background-alternative', 'border-gray-600')
  }

  updateStyles() {
    this.inputTargets.forEach(input => {
      this.updateLabelStyle(input)
    })
  }

  updateSubmitButton(currentRole) {
    // Enable submit only if the current role is different from initial
    this.submitTarget.disabled = currentRole === this.initialRole
  }
}
