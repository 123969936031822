import { Controller } from 'stimulus';
import debounce from 'debounce';

export default class InvestorFormsController extends Controller {
  static targets = ['amountInput'];

  static values = { fetchTxUrl: String };

  initialize() {
    this.fetchTx = debounce(this.fetchTx.bind(this), 300);
  }

  isValidTxHash(txHash) {
    return /^0x([A-Fa-f0-9]{64})$/.test(txHash);
  }

  async fetchTx(event) {
    event.preventDefault();
    const txHash = event.target.value;

    // As long as the user has inputted a tx hash, the amount input should be set to readonly
    // and the value should be cleared and finally set by the result of the fetched result
    if (txHash.length > 0) {
      this.amountInputTarget.readOnly = true;
      this.amountInputTarget.value = '';
    } else {
      this.amountInputTarget.readOnly = false;
    }

    // if (!this.isValidTxHash(txHash)) return

    const response = await fetch(`${this.fetchTxUrlValue}?tx_hash=${txHash}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })

    const { success, errors, amount } = await response.json()

    if (success) {
      this.amountInputTarget.value = amount;
    } else {
      console.error(errors);
    }
  }
}
