import { Controller } from '@hotwired/stimulus';
import gsap from 'gsap';

export default class extends Controller {
  static values = { batches: Object };
  static targets = [
    'currentMonth',
    'currentMonthCalendar',
    'nextMonth',
    'nextMonthCalendar',
  ];

  connect() {
    this.date1 = new Date();
    this.date2 = new Date();
    this.date2.setMonth(this.date2.getMonth() + 1);
    this.renderCalendars();
    document.addEventListener(
      'turbo:before-stream-render',
      this.handleTurboStream.bind(this)
    );
  }

  disconnect() {
    document.removeEventListener(
      'turbo:before-stream-render',
      this.handleTurboStream.bind(this)
    );
  }

  handleTurboStream(event) {
    const action = event.target.getAttribute('action');
    const elementId = event.target.getAttribute('target');

    if (action !== 'replace' || !/^(\d{4})-(\d{2})-(\d{2})$/.test(elementId)) {
      return;
    }

    // Prevent Turbo from immediately removing the element, this needs to be below the above if statement to not block other actions
    event.preventDefault();

    const element = document.getElementById(elementId);

    if (!element) {
      return console.error(
        `Element with id ${elementId} not found in distributions_calendar_controller.js`
      );
    }

    const button = element.querySelector('button');
    if (!button) {
      return console.error(
        `Button not found in element with id ${elementId} in distributions_calendar_controller.js`
      );
    }

    // Fade out old counter if exists and fade in new counter
    const counterElement = button.querySelector(
      "[data-testid='batch-counter']"
    );
    const count = parseInt(event.target.getAttribute('data-count'), 10) || 1;
    if (counterElement) {
      const isGreen = counterElement.classList.contains('bg-green-600');
      gsap.to(counterElement, {
        opacity: 0,
        duration: 0.2,
        delay: 0.2,
        onComplete: () => {
          const newCount = parseInt(counterElement.textContent) + count;
          counterElement.textContent = newCount;
          if (isGreen) {
            counterElement.classList.replace('bg-green-600', 'bg-red-600');
            counterElement.classList.replace('bg-green-300', 'bg-red-300');
          }
          if (newCount > 9) {
            counterElement.classList.replace('w-4', 'w-5');
            counterElement.classList.replace('h-4', 'h-5');
          }
          gsap.fromTo(
            counterElement,
            { opacity: 0 },
            { opacity: 1, duration: 0.2 }
          );
        },
      });
    } else {
      const batchCounter = document.createElement('div');
      batchCounter.className = `absolute top-0 right-0 ${
        count > 9 ? 'w-5 h-5' : 'w-4 h-4'
      } rounded-full bg-red-300 text-black text-xs flex items-center justify-center opacity-0 scale-95 font-medium`;
      batchCounter.setAttribute('data-testid', 'batch-counter');
      batchCounter.textContent = count.toString();
      button.appendChild(batchCounter);

      gsap.fromTo(
        batchCounter,
        { opacity: 0 },
        { opacity: 1, duration: 0.2, delay: 0.2 }
      );
    }
  }

  previousMonth() {
    this.animateOut(() => {
      this.date1.setMonth(this.date1.getMonth() - 2);
      this.date2.setMonth(this.date2.getMonth() - 2);
      this.fetchAndRenderCalendars();
    });
  }

  nextMonth() {
    this.animateOut(() => {
      this.date1.setMonth(this.date1.getMonth() + 2);
      this.date2.setMonth(this.date2.getMonth() + 2);
      this.fetchAndRenderCalendars();
    });
  }

  async fetchAndRenderCalendars() {
    const dateString = this.date1.toISOString().split('T')[0];
    const slug = window.location.pathname.split('/')[2];
    const url = `/partner/${slug}/distributions_calendar/fetch_batches/${dateString}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      this.batchesValue = data;
      this.renderCalendars();
      this.animateIn();
    } catch (error) {
      console.error('There was an error fetching the calendar data:', error);
    }
  }

  renderCalendars() {
    this.renderCalendar(
      this.date1,
      this.currentMonthTarget,
      this.currentMonthCalendarTarget
    );
    this.renderCalendar(
      this.date2,
      this.nextMonthTarget,
      this.nextMonthCalendarTarget
    );
    this.animateCountersIn();
  }

  renderCalendar(date, monthTarget, calendarTarget) {
    const monthName = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const today = new Date();
    const isToday = (d, m, y) =>
      d === today.getDate() &&
      m === today.getMonth() &&
      y === today.getFullYear();

    monthTarget.textContent = `${monthName} ${year}`;

    const firstDay =
      (new Date(date.getFullYear(), date.getMonth(), 1).getDay() + 6) % 7; // Adjust to make Monday the first day of the week
    const daysInMonth = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();
    const totalCells = Math.ceil((firstDay + daysInMonth) / 7) * 7;

    let calendarHTML = '';
    for (let i = 0; i < totalCells; i++) {
      let classes =
        'relative bg-card-background py-1.5 text-gray-300 hover:bg-gray-600/70 focus:z-10';
      let dayNumber = '';
      let isCurrentMonth = false;

      if (i < firstDay) {
        const prevMonth = new Date(date.getFullYear(), date.getMonth(), 0);
        dayNumber = prevMonth.getDate() - firstDay + i + 1;
      } else if (i < firstDay + daysInMonth) {
        dayNumber = i - firstDay + 1;
        isCurrentMonth = true;
        classes = 'relative py-1.5 focus:z-10';
        if (isToday(dayNumber, date.getMonth(), date.getFullYear())) {
          classes += ' bg-sky-medium hover:bg-sky-medium/70';
        } else {
          classes += ' bg-gray-600 hover:bg-gray-600/70';
        }
      } else {
        dayNumber = i - (firstDay + daysInMonth) + 1;
      }

      if (i === 0) classes += ' rounded-tl-lg';
      if (i === 6) classes += ' rounded-tr-lg';
      if (i === totalCells - 7) classes += ' rounded-bl-lg';
      if (i === totalCells - 1) classes += ' rounded-br-lg';

      const dateStr = `${date.getFullYear()}-${String(
        date.getMonth() + (isCurrentMonth ? 1 : i < firstDay ? 0 : 2)
      ).padStart(2, '0')}-${String(dayNumber).padStart(2, '0')}`;

      const slug = window.location.pathname.split('/')[2];

      calendarHTML += `<a class="w-full h-full" href="/partner/${slug}/distributions_calendar/${dateStr}" data-action="click->actions#openModal" data-turbo-frame="modal_content" id="${dateStr}">
                          <button type="button" class="${classes} w-full h-full">
                            <time datetime="${dateStr}" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">${dayNumber}</time>
                          </button>
                       </a>`;
    }

    calendarTarget.innerHTML = calendarHTML;
  }

  animateCountersIn() {
    const appendCounter = (button, count, className) => {
      if (count === 0) {
        return;
      }
      const sizeClass = count > 9 ? 'w-5 h-5' : 'w-4 h-4';
      const batchCounter = document.createElement('div');
      batchCounter.className = `absolute top-0 right-0 ${sizeClass} rounded-full ${className} text-black text-xs flex items-center justify-center opacity-0 scale-95 font-medium`;
      batchCounter.setAttribute('data-testid', 'batch-counter');
      batchCounter.textContent = count;
      button.appendChild(batchCounter);

      gsap.fromTo(
        batchCounter,
        { opacity: 0 },
        { opacity: 1, duration: 0.2, delay: 0.2 }
      );
    };

    const processButton = (button) => {
      const time = button.querySelector('time');
      const fullDate = time.getAttribute('datetime');
      const batches = this.batchesValue[fullDate];

      if (!batches) {
        return;
      }

      let incompleteCount = 0;
      let completedCount = 0;

      batches.forEach((batch) => {
        if (batch.completed) {
          completedCount++;
        } else {
          incompleteCount++;
        }
      });

      const totalCount = incompleteCount + completedCount;
      const className = incompleteCount > 0 ? 'bg-red-300' : 'bg-green-300';
      appendCounter(button, totalCount, className);
    };

    this.currentMonthCalendarTarget
      .querySelectorAll('button')
      .forEach(processButton);
    this.nextMonthCalendarTarget
      .querySelectorAll('button')
      .forEach(processButton);
  }

  animateOut(callback) {
    const timeline = gsap.timeline({ onComplete: callback });
    timeline
      .to(this.currentMonthTarget, { opacity: 0, duration: 0.2 })
      .to(this.nextMonthTarget, { opacity: 0, duration: 0.2 }, 0)
      .to(
        this.currentMonthCalendarTarget,
        { opacity: 0, y: 20, duration: 0.2 },
        0
      )
      .to(
        this.nextMonthCalendarTarget,
        { opacity: 0, y: 20, duration: 0.2 },
        0
      );
  }

  animateIn() {
    const timeline = gsap.timeline();
    timeline
      .fromTo(
        this.currentMonthTarget,
        { opacity: 0 },
        { opacity: 1, duration: 0.2 }
      )
      .fromTo(
        this.nextMonthTarget,
        { opacity: 0 },
        { opacity: 1, duration: 0.2 },
        0
      )
      .fromTo(
        this.currentMonthCalendarTarget,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, duration: 0.2 },
        0
      )
      .fromTo(
        this.nextMonthCalendarTarget,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, duration: 0.2 },
        0
      )
      .add(() => {
        this.animateCountersIn();
      });
  }
}
