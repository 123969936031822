import { Controller } from 'stimulus';
import { gsap } from 'gsap';

/**
 * @extends {Controller}
 * @property {boolean} hasPanelTarget
 * @property {HTMLElement} panelTarget
 * @property {boolean} hasModalTarget
 * @property {HTMLElement} modalTarget
 * @property {boolean} hasDialogTarget
 * @property {HTMLElement} dialogTarget
 * @property {boolean} hasSpinnerTarget
 * @property {HTMLElement} spinnerTarget
 * @property {boolean} hasTurboFrameTarget
 * @property {HTMLElement} turboFrameTarget
 */
export default class extends Controller {
  /** @type {string[]} */
  static targets = [
    'modal',
    'dialog',
    'panel',
    'spinner',
    'turboFrame'
  ]

  static values = {
    frameId: { type: String, default: 'trade_modal_panel' }
  }

  connect() {
    this.boundDialogClick = this.dialogClick.bind(this);
    this.boundEscapePress = this.escapePress.bind(this);
    this.boundTurboStreamHandler = this.handleTurboStream.bind(this);
    document.addEventListener(
      'turbo:before-stream-render',
      this.boundTurboStreamHandler
    );
  }

  disconnect() {
    document.removeEventListener('keydown', this.boundEscapePress);
    document.removeEventListener('click', this.boundDialogClick);
    document.removeEventListener(
      'turbo:before-stream-render',
      this.boundTurboStreamHandler
    );
    document.body.style.overflow = '';
  }

  handleTurboStream(event) {
    const { action, target } = event.detail.newStream;

    if (target === this.frameIdValue && action === 'replace') {
      event.preventDefault();
      this.handlePanelAdditionOrReplacement(event.detail.newStream);
    }
  }

  removeModalListeners() {
    document.removeEventListener('keydown', this.boundEscapePress);
    this.modalTarget.removeEventListener('mousedown', this.boundDialogClick);
  }

  handlePanelAdditionOrReplacement(newStream) {
    const currentContent = Array.from(this.turboFrameTarget.children)
    const newContent = Array.from(newStream.querySelector('template').content.children)
    this.updateContent(currentContent, newContent)
  }

  updateTurboFrameContent(event) {
    if (event.target === this.turboFrameTarget) {
      event.detail.render = (currentElement, newElement) => {
        // Get all children as arrays
        const currentContent = Array.from(currentElement.children)
        const newContent = Array.from(newElement.children)

        // Pass all content to updateContent
        this.updateContent(currentContent, newContent)
      }
    }
  }

  updateContent(currentContent, newContent) {
    this.hideSpinner();

    if (this.shouldCloseModal(newContent)) {
      this.closeModal();
      return;
    }

    this.updateModalListeners(newContent);

    if (!currentContent.length) {
      this.openModalWithContent(newContent);
    } else {
      this.updateModal( currentContent, newContent);
    }
  }

  hideSpinner() {
    gsap.to(this.spinnerTarget, {
      opacity: 0,
      duration: 0.2,
      ease: 'power1.out',
      onComplete: () => {
        this.spinnerTarget.classList.add('hidden');
      }
    });
  }

  shouldCloseModal(content) {
    return content.some(element => element.dataset.modal === 'close');
  }

  updateModalListeners(content) {
    this.removeModalListeners();

    if (!content.some(element => element.dataset.lockModal === 'true')) {
      this.modalTarget.addEventListener('mousedown', this.boundDialogClick);
      document.addEventListener('keydown', this.boundEscapePress);
    }
  }

  openModalWithContent(content) {
    this.openModal(null, {
      lockModal: content.some(element => element.dataset.lockModal === 'true')
    });

    this.turboFrameTarget.classList.remove('hidden');
    this.animateContentTransition(this.turboFrameTarget, [], content);
  }

  updateModal(currentContent, newContent) {
    const { newHeight, newWidth } = this.measureNewContent(this.turboFrameTarget, newContent);
    this.animateContentTransition(this.turboFrameTarget, currentContent, newContent, newHeight, newWidth);
  }

  measureNewContent(target, content) {
    const tempContainer = document.createElement('div');
    Object.assign(tempContainer.style, {
      position: 'absolute',
      visibility: 'hidden',
      padding: getComputedStyle(target).padding
    });
    tempContainer.className = target.className;
    tempContainer.classList.remove('hidden');

    content.forEach(element => {
      tempContainer.appendChild(element.cloneNode(true));
    });
    document.body.appendChild(tempContainer);

    const dimensions = {
      newHeight: tempContainer.getBoundingClientRect().height,
      newWidth: tempContainer.getBoundingClientRect().width
    };

    document.body.removeChild(tempContainer);
    return dimensions;
  }

  animateContentTransition(target, currentContent, newContent, newHeight = null, newWidth = null) {
    const initialState = {
      opacity: 0,
      y: this.isMobile ? 16 : 0,
      scale: this.isMobile ? 1 : 0.95
    };

    const finalState = {
      opacity: 1,
      y: 0,
      scale: 1,
      duration: 0.2,
      ease: 'power1.out'
    };

    if (currentContent.length) {
      // Animate out current content
      gsap.to(currentContent, {
        ...initialState,
        duration: 0.2,
        ease: 'power1.in',
        onComplete: () => {
          this.replaceContent(target, currentContent, newContent, newHeight, newWidth, finalState);
        }
      });
    } else {
      // Just animate in new content
      gsap.set(target, initialState);
      newContent.forEach(element => target.appendChild(element));
      gsap.to(target, finalState);
    }
  }

  replaceContent(target, currentContent, newContent, newHeight, newWidth, animationState) {
    gsap.set(newContent, {
      opacity: 0,
      y: this.isMobile ? 16 : 0,
      scale: this.isMobile ? 1 : 0.95
    });

    const onSizeTransitionComplete = () => {
      currentContent.forEach(element => {
        if (element.parentElement === target) {
          element.remove();
        }
      });

      newContent.forEach(element => target.appendChild(element));

      target.style.width = '';
      target.style.height = '';

      gsap.to(newContent, animationState);
    };

    if (newHeight && newWidth) {
      gsap.to(target, {
        width: newWidth,
        height: newHeight,
        duration: 0.2,
        ease: 'power1.inOut',
        onComplete: () =>  {
          onSizeTransitionComplete()
          this.reEnableTurboOnContent(target);
        }
      });
    } else {
      onSizeTransitionComplete();
    }
  }

  reEnableTurboOnContent(target) {
    // Turbo's initialization cycle had already completed
    // by the time the content was asynchronously rendered
    // so we need to re-enable Turbo on that content
    const tempElement = document.createElement('div');
    tempElement.innerHTML = target.outerHTML;
    tempElement.id = 'temp-element';
    tempElement.hidden = true;
    window.Turbo.session.renderStreamMessage(tempElement.outerHTML);
    tempElement.remove();
  }

  dialogClick = (e) => {
    console.log('dialog click');
    if (this.hasTurboFrameTarget && this.turboFrameTarget.contains(e.target)) {
      return;
    }

    this.closeModal();
  };

  escapePress = (e) => {
    if (e.key === 'Escape') {
      this.closeModal();
    }
  };

  openModal(e, options = {}) {
    this.lastActiveElement = document.activeElement;
    document.body.style.overflow = 'hidden';

    // Remove existing listeners first
    this.removeModalListeners();

    // Only add listeners if we're not locking the modal
    if (!options.lockModal && e?.currentTarget?.dataset?.lockModal !== 'true') {
      this.modalTarget.addEventListener('mousedown', this.boundDialogClick);
      document.addEventListener('keydown', this.boundEscapePress);
    }

    gsap.set(this.spinnerTarget, { opacity: 1 });
    this.spinnerTarget.classList.remove('hidden');
    this.modalTarget.classList.remove('hidden');
    this.dialogTarget.classList.remove('hidden');
    gsap.set(this.dialogTarget, { opacity: 0 });

    gsap.to(this.dialogTarget, {
      opacity: 1,
      duration: 0.2,
      ease: 'power1.out'
    });
  }

  closeModal(e) {
    this.removeModalListeners();

    this.spinnerTarget.classList.add('hidden');

    gsap.to(this.turboFrameTarget, {
      opacity: 0,
      y: this.isMobile ? 16 : 0,
      scale: this.isMobile ? 1 : 0.95,
      duration: 0.2,
      ease: 'power1.in'
    });

    gsap.to(this.dialogTarget, {
      opacity: 0,
      duration: 0.2,
      ease: 'power1.out',
      onComplete: () => {
        this.modalTarget.classList.add('hidden');
        this.dialogTarget.classList.add('hidden');
        this.spinnerTarget.classList.add('hidden');
        this.turboFrameTarget.classList.add('hidden');
        this.turboFrameTarget.innerHTML = '';

        this.turboFrameTarget.style.width = '';
        this.turboFrameTarget.style.height = '';

        document.body.style.overflow = '';

        if (this.lastActiveElement) {
          this.lastActiveElement.focus();
        }
      }
    });
  }

  get isMobile() {
    return window.matchMedia('(max-width: 640px)').matches;
  }
}
