const blurNumberInput = (e) => {
  const { target } = e
  if (target.tagName === 'INPUT' && target.type === 'number') {
    target.blur()
  }
}

document.addEventListener('turbo:load', () => {
  // Remove any existing listener to prevent duplicates
  document.removeEventListener('wheel', blurNumberInput, { capture: true })

  // Add the wheel event listener
  document.addEventListener('wheel', blurNumberInput, { capture: true })
});
