import Web3Controller from './web3_controller';
import { walletShortname } from '../helpers/wallet_helpers';
import { Web3Account } from '../web3/magnet/types';

export default class extends Web3Controller {
  static targets = [
    'connectorIcon',
    'connectorName',
    'chainIcon',
    'chainName',
    'address',
  ];
  declare connectorIconTarget: HTMLImageElement;
  declare connectorNameTarget: HTMLElement;
  declare chainIconTarget: HTMLImageElement;
  declare chainNameTarget: HTMLElement;
  declare addressTarget: HTMLElement;

  static values = {
    ethLogo: String,
    bscLogo: String,
    chainPlaceholderLogo: String,
    wallets: Array<String>,
    isImpersonating: Boolean,
  };
  declare ethLogoValue: string;
  declare bscLogoValue: string;
  declare chainPlaceholderLogoValue: string;
  declare walletsValue: string[];
  declare isImpersonatingValue: boolean;

  onAccountConnected(account: Web3Account) {
    this.addressTarget.innerHTML = walletShortname(account.address!);

    if (!this.#accountIsAllowed(account)) {
      this.#disablePage(account);
    } else {
      this.#closeSessionOrConnectModal();
      this.#setDropdownContent(account);
    }
    super.onAccountConnected(account);
  }

  #setDropdownContent(account: Web3Account) {
    this.connectorIconTarget.src = account.connector?.icon || '';
    this.connectorNameTarget.textContent =
      account.connector?.name || 'Unknown wallet';

    let chainIcon = '';
    switch (account.chain.name) {
      case 'Ethereum':
        chainIcon = this.ethLogoValue;
        break;
      case 'BNB Smart Chain':
        chainIcon = this.bscLogoValue;
        break;
      default:
        chainIcon = this.chainPlaceholderLogoValue;
    }
    this.chainIconTarget.src = chainIcon;
    this.chainNameTarget.textContent =
      this.magnet?.web3Account?.chain?.name || 'Unknown chain';
  }

  #accountIsAllowed(account: Web3Account): boolean {
    if (!this.#isInvestorPortal()) return true;
    if (this.isImpersonatingValue) return true;
    if (!this.actionsController) return true;

    return this.walletsValue.includes(account.address!.toLowerCase());
  }

  #disablePage(account: Web3Account) {
    this.actionsController.openModal(null, {
      showSessionsModal: true,
      lockModal: true,
      address: account.address!.toLowerCase(),
    });
  }

  #closeSessionOrConnectModal() {
    // We only want to close the modal if it's currently the sessions or sign-in modal open.
    const isSessionsOrConnectBody =
      document.getElementById('sessions-modal-body') ||
      document.getElementById('connect-modal');
    if (isSessionsOrConnectBody) {
      this.actionsController?.closeModal();
    }
  }

  openConnectModal(event?: Event) {
    event?.preventDefault();
    this.actionsController.openModal(null, {
      showConnectModal: true,
    });
  }

  #isInvestorPortal(): boolean {
    return document.getElementById('investor-portal-main-content') !== null;
  }

  get actionsController(): any {
    const actionsControllerElement = document.querySelector(
      '[data-controller~=actions]'
    );
    if (actionsControllerElement) {
      return this.application.getControllerForElementAndIdentifier(
        actionsControllerElement,
        'actions'
      );
    }
  }
}
